import { SubconceptSortingTypes } from "@helpers/enum";
import { Collection } from "./collection";

export class Concept {
  public id: string;
  public name: string;
  public description?: string;
  public imageUrl: string;
  public subConceptsNumber?: number;
  public productsNumber?: number;
  public collectionId: string;
  public collection?: Collection;
  public sortSettings: SubconceptSortingTypes;
  public orderNumber: number;
}