import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { SubConcept } from '../_models';
import { environment } from '../environments/environment';


@Injectable({ providedIn: 'root' })
export class SubConceptsService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<SubConcept[]>(`${environment.apiURL}subConcepts`).pipe(map(subConcepts => {
            return subConcepts;
        }));
    }

    getAllWithNumbers() {
        return this.http.get<SubConcept[]>(`${environment.apiURL}subConcepts/admin`).pipe(map(subConcepts => {
            return subConcepts;
        }));
    }

    getById(id: string) {
        return this.http.get<SubConcept>(`${environment.apiURL}subConcepts/${id}`);
    }

    getAllByConcept(collectionId: string) {
        return this.http.get<SubConcept[]>(`${environment.apiURL}subConcepts/${collectionId}/concept`);
    }

    add(subConcepts: SubConcept) {
        return this.http.post<SubConcept>(`${environment.apiURL}subConcepts/`, subConcepts);
    }

    update(subConceptsId: string, subConcepts: SubConcept) {
        return this.http.put<SubConcept>(`${environment.apiURL}subConcepts/${subConceptsId}`, subConcepts);
    }

    delete(subConceptsId: string) {
        return this.http.delete<boolean>(`${environment.apiURL}subConcepts/${subConceptsId}?cascading=true`);
    }

    updateOrder(id: string, sortSettings) {
        return this.http.put<boolean>(`${environment.apiURL}subConcepts/${id}/sort`, { option: sortSettings.option, data: sortSettings.sorting });
    }

    uploadSubConceptImage(file: File): any {
        const formData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post(`${environment.apiURL}subConcepts/uploadSubConceptImage/`, formData);
    }

    uploadSubConceptFooterImage(file: File): any {
        const formData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post(`${environment.apiURL}subConcepts/UploadSubConceptFooterImage/`, formData);
    }

    uploadList(data: any) {
        return this.http.post<SubConcept>(`${environment.apiURL}subConcepts/upload`, data);
    }
}