import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { Purchase } from '../_models';


@Injectable({ providedIn: 'root' })
export class PurchaseService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<Purchase[]>(`${environment.apiURL}purchase`).pipe(map(purchases => {
            return purchases;
        }));
    }

    getById(id: string) {
        return this.http.get<Purchase>(`${environment.apiURL}purchase/${id}`);
    }

    getByDate(startDate: string, endDate: string) {
        return this.http.get<Purchase[]>(`${environment.apiURL}purchase/GetByDates/${startDate}/${endDate}`).pipe(map(purchases => {
            return purchases;
        }));
    }

    add(purchase: Purchase) {
        return this.http.post<Purchase>(`${environment.apiURL}purchase/`, purchase);
    }

    generatePurchase(id: string, file: Blob) {
        const formData = new FormData();
        formData.append('file', file, 'pdf');
        return this.http.post(`${environment.apiURL}purchase/generatePurchase/${id}`, formData);
    }

    finishPurchase(id: string) {
        return this.http.post(`${environment.apiURL}purchase/finishPurchase/${id}`, {});
    }

    resendEmail(purchaseId: string) {
        return this.http.post<Purchase>(`${environment.apiURL}purchase/resendEmail/`, { id: purchaseId });
    }

    // update(purchaseId: string, purchase: Purchase) {
    //     return this.http.put<Purchase>(`${environment.apiURL}purchase/${purchaseId}`, purchase);
    // }

    delete(purchaseId: string) {
        return this.http.delete<boolean>(`${environment.apiURL}purchase/${purchaseId}`);
    }

}