import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InterceptorSkipHeader } from '_helpers/Interceptors/interceptor.helper';
import { Socials } from '_models/socials';
import { map } from 'rxjs/operators';
import { environment } from '../environments/environment';


@Injectable({ providedIn: 'root' })
export class SocialsService {
    constructor(private http: HttpClient) { }

    getAllHomepage() {
        const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
        return this.http.get<Socials[]>(`${environment.apiURL}socials/visible`, { headers }).pipe(map(socials => {
            return socials;
        }));
    }

    getAll() {
        return this.http.get<Socials[]>(`${environment.apiURL}socials`).pipe(map(socials => {
            return socials;
        }));
    }

    getById(id: string) {
        return this.http.get<Socials>(`${environment.apiURL}socials/${id}`);
    }

    add(socials: Socials) {
        return this.http.post<Socials>(`${environment.apiURL}socials/`, socials);
    }

    update(socialsId: string, socials: Socials) {
        return this.http.put<Socials>(`${environment.apiURL}socials/${socialsId}`, socials);
    }

    uploadSocialsImage(file: File): any {
        const formData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post(`${environment.apiURL}socials/uploadImage/`, formData);
    }

    delete(id: string) {
        return this.http.delete<boolean>(`${environment.apiURL}socials/${id}`);
    }
}