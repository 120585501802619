import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { Comercial } from '../_models';
import { environment } from '../environments/environment';
import { I18nLanguage } from '@helpers/enum';

@Injectable({ providedIn: 'root' })
export class ComercialService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<Comercial[]>(`${environment.apiURL}comercial`).pipe(map(comercial => {
            return comercial;
        }));
    }

    getById(id: string) {
        return this.http.get<Comercial>(`${environment.apiURL}comercial/${id}`);
    }

    add(comercial: Comercial) {
        return this.http.post<Comercial>(`${environment.apiURL}comercial/register`, comercial);
    }

    update(comercialId: string, comercial: Comercial) {
        return this.http.put<Comercial>(`${environment.apiURL}comercial/${comercialId}`, comercial);
    }

    updateLanguage(comercialId: string, language: I18nLanguage) {
        return this.http.put<Comercial>(`${environment.apiURL}comercial/${comercialId}/language`, language);
    }

    delete(id: string) {
        return this.http.delete<boolean>(`${environment.apiURL}comercial/${id}`);
    }

    setProductSeen(productId: string) {
        return this.http.put<Comercial>(`${environment.apiURL}comercial/${productId}/productSeen`, {});
    }
}