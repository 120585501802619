import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { I18nLanguage } from '@helpers/enum';
import { map } from 'rxjs/operators';
import { environment } from '../../src/environments/environment';
import { User } from '../_models';


@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<User[]>(`${environment.apiURL}users`).pipe(map(user => {
            return user;
        }));
    }

    getById(id: string) {
        return this.http.get<User>(`${environment.apiURL}users/${id}`);
    }

    add(user: User) {
        return this.http.post<User>(`${environment.apiURL}users/register`, user);
    }

    update(userId: string, user: User) {
        return this.http.put<User>(`${environment.apiURL}users/${userId}`, user);
    }

    updateLanguage(userId: string, language: I18nLanguage) {
        return this.http.put<User>(`${environment.apiURL}users/${userId}/language`, language);
    }

    activate(userId: string) {
        return this.http.put<User>(`${environment.apiURL}users/${userId}/activate`, {});
    }

    delete(id: string) {
        return this.http.delete<boolean>(`${environment.apiURL}users/${id}`);
    }

    block(id: string) {
        return this.http.get<boolean>(`${environment.apiURL}users/block/${id}`);
    }

    unblock(id: string) {
        return this.http.get<boolean>(`${environment.apiURL}users/unblock/${id}`);
    }

    setProductSeen(productId: string) {
        return this.http.put<User>(`${environment.apiURL}users/${productId}/productSeen`, {});
    }

    refreshTimer(userId: string) {
        return this.http.put<User>(`${environment.apiURL}users/${userId}/timer`, {});
    }
}