<div>
  <form [formGroup]="twofa" (ngSubmit)="twofaSignup()" class="code2fa">
    <label>Enter 6-digit code you received on email</label>
    <div class="numbers">
      <mat-form-field>
        <input matInput #pincode1 id="pincode1" type="text" name="pincode1" tabindex="1" maxlength="1"
          autocomplete="off" [formControl]="twofa.controls['pincode1']" (keyup)="changeInput($event, 1)">
      </mat-form-field>
      <mat-form-field>
        <input matInput #pincode2 id="pincode2" type="text" name="pincode2" tabindex="2" maxlength="1"
          autocomplete="off" [formControl]="twofa.controls['pincode2']" (keyup)="changeInput($event, 2)">
      </mat-form-field>
      <mat-form-field>
        <input matInput #pincode3 id="pincode3" type="text" name="pincode3" tabindex="3" maxlength="1"
          autocomplete="off" [formControl]="twofa.controls['pincode3']" (keyup)="changeInput($event, 3)">
      </mat-form-field>
      <mat-form-field>
        <input matInput #pincode4 id="pincode4" type="text" name="pincode4" tabindex="4" maxlength="1"
          autocomplete="off" [formControl]="twofa.controls['pincode4']" (keyup)="changeInput($event, 4)">
      </mat-form-field>
      <mat-form-field>
        <input matInput #pincode5 id="pincode5" type="text" name="pincode5" tabindex="5" maxlength="1"
          autocomplete="off" [formControl]="twofa.controls['pincode5']" (keyup)="changeInput($event, 5)">
      </mat-form-field>
      <mat-form-field>
        <input matInput #pincode6 id="pincode6" type="text" name="pincode6" tabindex="6" maxlength="1"
          autocomplete="off" [formControl]="twofa.controls['pincode6']" (keyup)="changeInput($event, 6)">
      </mat-form-field>
    </div>
  </form>
  <div class="request_new_2fa">
    <span class="message">{{ 'AUTHENTICATION.EMAIL' | translate}}</span>
    <button type="button" mat-raised-button class="mat-primary full-width mb-1" [disabled]="timerNewCode > 0"
      (click)="requestNewCode()">
      <span *ngIf="timerNewCode > 0">{{ 'AUTHENTICATION.ASK_NEW_CODE_TIME' | translate}}
        <span>{{timerNewCode}}</span></span>
      <span *ngIf="timerNewCode <= 0">{{ 'AUTHENTICATION.ASK_NEW_CODE' | translate}}</span>
    </button>
  </div>
</div>