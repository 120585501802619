import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { I18nLanguage } from '@helpers/enum';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { environment } from '../../src/environments/environment';
import { AuthUser, UserType } from '../_models';
import { AdminService } from './admin.service';
import { ComercialService } from './comercial.service';
import { UserService } from './user.service';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public currentUserSubject: BehaviorSubject<AuthUser>;
    public currentUser: Observable<AuthUser>;

    constructor(
        private http: HttpClient,
        private adminService: AdminService,
        private userService: UserService,
        private comercialService: ComercialService,
    ) {
        this.currentUserSubject = new BehaviorSubject<AuthUser>(JSON.parse(localStorage.getItem('currentUser')));
    }

    public get currentUserValue(): AuthUser {
        return this.currentUserSubject.value;
    }

    loginGlobal(email: string, password: string, deviceFingerprint) {
        const url = `${environment.apiURL}Authentication/Login`;
        return this.http.post<any>(url, { email, password, deviceFingerprint }).pipe(
            concatMap((response) => {
                if ((response.isAdmin && !response.isUser && !response.isComercial) || (!response.isAdmin && response.isUser && !response.isComercial) || (!response.isAdmin && !response.isUser && response.isComercial)) {
                    if (response.isAdmin && !response.isUser && !response.isComercial) { //Significa que é apenas admin
                        return this.login(UserType.ADMIN, email, password, deviceFingerprint).pipe(map((data) => { return { ...data, userType: UserType.ADMIN } }));
                    } else if (!response.isAdmin && response.isUser && !response.isComercial) { //Significa que é apenas utilizador
                        return this.login(UserType.USER, email, password, deviceFingerprint).pipe(map((data) => { return { ...data, userType: UserType.USER } }));
                    } else if (!response.isAdmin && !response.isUser && response.isComercial) { //Significa que é apenas comercial
                        return this.login(UserType.COMERCIAL, email, password, deviceFingerprint).pipe(map((data) => { return { ...data, userType: UserType.COMERCIAL } }));
                    }
                }

                //Significa que pode ser mais do que 1, e ele terá de escolher com o qual quer entrar
                return of(response);
            }));
    }


    login(userType: UserType, email: string, password: string, deviceFingerprint) {
        let url = `${environment.apiURL}Users/Login`;
        if (userType == UserType.ADMIN) {
            url = `${environment.apiURL}Admin/Login`;
        } else if (userType == UserType.COMERCIAL) {
            url = `${environment.apiURL}Comercial/Login`;
        }

        return this.http.post<AuthUser>(url, { email, password, deviceFingerprint })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    user.userType = userType;
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                }
                return user;
            }));
    }

    refreshToken(refreshToken: string, fingerprint: any): Observable<any> {
        const url = `${environment.apiURL}Authentication/RefreshAccessToken`;
        return this.http.post<any>(url, { refreshToken: refreshToken, fingerprint: fingerprint }).pipe(
            map((response) => {
                return response;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }

    forgetPasswordGlobal(email: string) {
        var url = `${environment.apiURL}Authentication/forgetPassword`;
        return this.http.post<any>(url, { email }).pipe(
            concatMap((response) => {
                if ((response.isAdmin && !response.isUser && !response.isComercial) || (!response.isAdmin && response.isUser && !response.isComercial) || (!response.isAdmin && !response.isUser && response.isComercial)) {
                    if (response.isAdmin && !response.isUser && !response.isComercial) { //Significa que é apenas admin
                        return this.forgetPassword(UserType.ADMIN, email).pipe(map((data) => { return { ...data, userType: UserType.ADMIN } }));
                    } else if (!response.isAdmin && response.isUser && !response.isComercial) { //Significa que é apenas utilizador
                        return this.forgetPassword(UserType.USER, email).pipe(map((data) => { return { ...data, userType: UserType.USER } }));
                    } else if (!response.isAdmin && !response.isUser && response.isComercial) { //Significa que é apenas comercial
                        return this.forgetPassword(UserType.COMERCIAL, email).pipe(map((data) => { return { ...data, userType: UserType.COMERCIAL } }));
                    }
                }

                //Significa que pode ser mais do que 1, e ele terá de escolher com o qual quer entrar
                return of(response);
            }));
    }


    forgetPassword(userType: UserType, email: string) {
        var url = `${environment.apiURL}Users/forgetPassword`;
        if (userType == UserType.ADMIN) {
            url = `${environment.apiURL}Admin/forgetPassword`;
        } else if (userType == UserType.COMERCIAL) {
            url = `${environment.apiURL}Comercial/forgetPassword`;
        }

        return this.http.get<any>(url + "/" + email, {})
            .pipe(map(response => {
                return response;
            }));
    }


    validateTokenGlobal(recoveryToken: string) {
        const url = `${environment.apiURL}Authentication/validateRecoveryToken`;
        return this.http.post<any>(url, { recoveryToken }).pipe(
            concatMap((response) => {
                if ((response.isAdmin && !response.isUser && !response.isComercial) || (!response.isAdmin && response.isUser && !response.isComercial) || (!response.isAdmin && !response.isUser && response.isComercial)) {
                    if (response.isAdmin && !response.isUser && !response.isComercial) { //Significa que é apenas admin
                        return this.validateToken(UserType.ADMIN, recoveryToken).pipe(map((data) => { return { ...data, userType: UserType.ADMIN } }));
                    } else if (!response.isAdmin && response.isUser && !response.isComercial) { //Significa que é apenas utilizador
                        return this.validateToken(UserType.USER, recoveryToken).pipe(map((data) => { return { ...data, userType: UserType.USER } }));
                    } else if (!response.isAdmin && !response.isUser && response.isComercial) { //Significa que é apenas comercial
                        return this.validateToken(UserType.COMERCIAL, recoveryToken).pipe(map((data) => { return { ...data, userType: UserType.COMERCIAL } }));
                    }
                }

                //Significa que pode ser mais do que 1, e ele terá de escolher com o qual quer entrar
                return of(response);
            }));
    }

    validateToken(userType: UserType, recoveryToken: string) {
        var url = `${environment.apiURL}Users/validateRecoveryToken`;
        if (userType == UserType.ADMIN) {
            url = `${environment.apiURL}Admin/validateRecoveryToken`;
        } else if (userType == UserType.COMERCIAL) {
            url = `${environment.apiURL}Comercial/validateRecoveryToken`;
        }

        return this.http.post<any>(url, { recoveryToken })
            .pipe(map(response => {
                return {
                    response: response,
                    userType: userType
                };
            }));
    }

    updatePassword(userType: UserType, recoveryToken: string, password: string) {
        var url = `${environment.apiURL}Users/updatePasswordWithToken`;
        if (userType == UserType.ADMIN) {
            url = `${environment.apiURL}Admin/updatePasswordWithToken`;
        } else if (userType == UserType.COMERCIAL) {
            url = `${environment.apiURL}Comercial/updatePasswordWithToken`;
        }

        return this.http.post<any>(url, { recoveryToken, password })
            .pipe(map(response => {
                return response;
            }));
    }

    updatePasswordNoLogin(userType: UserType, email: string, password: string, newPassword: string) {
        var url = `${environment.apiURL}Users/UpdatePasswordNoLogin`;
        if (userType == UserType.ADMIN) {
            url = `${environment.apiURL}Admin/UpdatePasswordNoLogin`;
        } else if (userType == UserType.COMERCIAL) {
            url = `${environment.apiURL}Comercial/UpdatePasswordNoLogin`;
        }

        return this.http.post<any>(url, { email, password, newPassword })
            .pipe(map(response => {
                return response;
            }));
    }

    updateLanguage(user: AuthUser, newLanguage: I18nLanguage) {
        if (user.userType == UserType.USER) {
            return this.userService.updateLanguage(user.id, newLanguage).subscribe((result) => {
                user.language = newLanguage;
                this.currentUserSubject.next(user);
                localStorage.setItem('currentUser', JSON.stringify(user));
                return result;
            })
        } else if (user.userType == UserType.ADMIN) {
            return this.adminService.updateLanguage(user.id, newLanguage).subscribe((result) => {
                user.language = newLanguage;
                this.currentUserSubject.next(user);
                localStorage.setItem('currentUser', JSON.stringify(user));
                return result;
            })
        } else if (user.userType == UserType.COMERCIAL) {
            return this.comercialService.updateLanguage(user.id, newLanguage).subscribe((result) => {
                user.language = newLanguage;
                this.currentUserSubject.next(user);
                localStorage.setItem('currentUser', JSON.stringify(user));
                return result;
            })
        }
    }

    checkTwoFactorAuthenticationCode(userType: UserType, id: string, loginToken: string, deviceFingerprint: any, rememberMe: boolean) {
        var url = `${environment.apiURL}Users/Validate2FA`;
        if (userType == UserType.ADMIN) {
            url = `${environment.apiURL}Admin/Validate2FA`;
        } else if (userType == UserType.COMERCIAL) {
            url = `${environment.apiURL}Comercial/Validate2FA`;
        }

        return this.http.post<any>(url, { id, loginToken, deviceFingerprint, rememberMe })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    user.userType = userType;
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                }

                return user;
            }));
    }

    requestNewTwoFactorAuthenticationCode(userType: UserType, id: string) {
        var url = `${environment.apiURL}Users/RequestNew2Fa`;
        if (userType == UserType.ADMIN) {
            url = `${environment.apiURL}Admin/RequestNew2Fa`;
        } else if (userType == UserType.COMERCIAL) {
            url = `${environment.apiURL}Comercial/RequestNew2Fa`;
        }

        return this.http.get<any>(url + "/" + id)
            .pipe(map(data => {
                return data;
            }));
    }

    setProductSeen(productId: string) {
        var user = this.currentUserValue;

        if (user.productsSeen.indexOf(productId) >= 0) {
            return;
        }

        if (user.userType == UserType.ADMIN) {
            return this.adminService.setProductSeen(productId).subscribe((result) => {
                user.productsSeen.push(productId);
                this.currentUserSubject.next(user);
                localStorage.setItem('currentUser', JSON.stringify(user));
                return result;
            })
        } else if (user.userType == UserType.COMERCIAL) {
            return this.comercialService.setProductSeen(productId).subscribe((result) => {
                user.productsSeen.push(productId);
                this.currentUserSubject.next(user);
                localStorage.setItem('currentUser', JSON.stringify(user));
                return result;
            })
        }
        return this.userService.setProductSeen(productId).subscribe((result) => {
            user.productsSeen.push(productId);
            this.currentUserSubject.next(user);
            localStorage.setItem('currentUser', JSON.stringify(user));
            return result;
        })
    }

    updateTokens(accessToken, refreshToken) {
        var user = this.currentUserValue;
        user.token = accessToken;
        user.refreshToken = refreshToken;

        this.currentUserSubject.next(user);
        localStorage.setItem('currentUser', JSON.stringify(user));
    }
}