<div class="header">
    <div class="top_bar" fxLayout="row">
        <div fxFlex="40">
            <div style='color: black;text-decoration: none;font-weight: bold;display: table;'>
                <div style="vertical-align: middle;display:table-cell;">
                    <img [src]="configuration.logo_path" [alt]="configuration.long_name" height='62'
                        style='margin:0;padding:0;max-width:100%;border:0'>
                    <!-- <span style="font-weight: 400; font-size: 16px; display: block; line-height: 16px;">
                        {{configuration.long_name}}
                    </span> -->
                    <span class="sub-name">
                        Fábrica de Lanifícios
                    </span>
                </div>
            </div>
        </div>
        <div class="align-center" fxFlex="30" fxLayout="column">
            <b><u>{{'CHECKOUT_FORM.COLLECTION' | translate}}</u></b>
            <b>{{collectionName}}</b> 
        </div>
        <div class="align_end" fxFlex="30" fxLayout="column">
            <div><b>{{'CHECKOUT_FORM.DATE' | translate}}</b> {{purchase.purchaseDate | timeFormat}}</div>
            <div><b>{{'CHECKOUT_FORM.PAGE' | translate}}</b> {{currentPage}} / {{totalPages}}</div>
        </div>
    </div>
    <div class="middle_bar">
        <div fxFlex="33.3">
            <table style="border-collapse: collapse">
                <tr
                    style="border-bottom: 1px solid #e8e8e8; border-top: 2px solid black; border-left: 2px solid black; border-right: 2px solid black;">
                    <td style="padding: 2px;">
                        <span *ngIf="purchase.pUser.userTypes == 1">{{purchase.pUser.organization}}</span>
                        <span *ngIf="purchase.pUser.userTypes == 2">(Admin)</span>
                        <span *ngIf="purchase.pUser.userTypes == 3">(Comercial)</span>
                    </td>
                </tr>
                <tr
                    style="border-bottom: 2px solid black; border-left: 2px solid black; border-right: 2px solid black;">
                    <td style="padding: 2px">
                        <span *ngIf="purchase.pUser.userTypes == 1"><b>ATT:</b> {{purchase.pUser.buyerName}}</span>
                        <span *ngIf="purchase.pUser.userTypes == 2"><b>ATT:</b> Admin</span>
                        <span *ngIf="purchase.pUser.userTypes == 3"><b>ATT:</b> {{purchase.pUser.name}}</span>
                    </td>
                </tr>
            </table>
        </div>
        <div class="align-center order_id" fxFlex="33.3">
            <span><b>{{'CHECKOUT_FORM.ORDER' | translate}} </b> {{purchase.orderId}}</span>
        </div>
        <div class="align_end" fxFlex="33.3" fxLayout="column">
            <div *ngIf="purchase.pUser.userTypes == 1">
                <div><b>{{'CHECKOUT_FORM.MARKET_RESPONSIBLE' | translate}}</b>: {{purchase.pUser.marketResp}}</div>
                <div>{{purchase.pUser.marketEmailResp}}</div>
            </div>
            <div *ngIf="purchase.pUser.userTypes == 2">
                <div><b>{{'CHECKOUT_FORM.MARKET_RESPONSIBLE' | translate}}</b>: (Admin)</div>
                <div>Admin Email</div>
            </div>
            <div *ngIf="purchase.pUser.userTypes == 3">
                <div><b>{{'CHECKOUT_FORM.MARKET_RESPONSIBLE' | translate}}</b>: (Comercial)</div>
                <div>Comercial Email</div>
            </div>
        </div>
    </div>
    <div class="bottom_bar">
        <b>Obs: </b> {{purchase?.observations}}
    </div>
</div>