import { AuthenticationStep, I18nLanguage } from "_helpers/enum";

export class AuthUser {
    id: string;
    name: string;
    email: string;
    password: string;
    token: string;
    refreshToken: string;
    phoneNumber: string;
    userType: UserType;
    errorType: AuthenticationStep;
    message: string;
    language: I18nLanguage;
    productsSeen: string[];
}

export enum UserType {
    NO_LOGIN = 0,
    USER = 1,
    ADMIN = 2,
    COMERCIAL = 3
}