import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CollectionSortingTypes, ConceptSortingTypes, ProductSortingTypes, SubconceptSortingTypes, UserLoginDuration } from "_helpers/enum";

@Pipe({ name: 'enumTranslatePipe' })
export class EnumTranslatePipe implements PipeTransform {

    constructor(private _translateService: TranslateService) { }

    transform(optionValue: number, enumType: string) {
        if (enumType == "loginLimit") {
            switch (optionValue) {
                case UserLoginDuration.HOUR_12:
                    return this._translateService.instant("PIPES.12_HOURS");
                case UserLoginDuration.HOUR_24:
                    return this._translateService.instant("PIPES.1_DAY");
                case UserLoginDuration.HOUR_48:
                    return this._translateService.instant("PIPES.2_DAYS");
                case UserLoginDuration.HOUR_168:
                    return this._translateService.instant("PIPES.1_WEEK");
                case UserLoginDuration.HOUR_336:
                    return this._translateService.instant("PIPES.2_WEEK");
                case UserLoginDuration.HOUR_730:
                    return this._translateService.instant("PIPES.1_MONTH");
                case UserLoginDuration.HOUR_1460:
                    return this._translateService.instant("PIPES.2_MONTH");
                case UserLoginDuration.HOUR_2190:
                    return this._translateService.instant("PIPES.3_MONTH");
                default:
                    return this._translateService.instant("PIPES.NO_LIMIT");
            }
        } else if (enumType == "conceptSettings") {
            switch (optionValue) {
                case ConceptSortingTypes.ALPHABETICAL:
                    return this._translateService.instant("PIPES.ALPHABETICAL");
                case ConceptSortingTypes.ORDER_NUMBER:
                    return this._translateService.instant("PIPES.ORDER_NUMBER");
                case ConceptSortingTypes.DEFAULT_ORDER:
                default:
                    return this._translateService.instant("PIPES.DEFAULT_ORDER");
            }
        } else if (enumType == "collectionSettings") {
            switch (optionValue) {
                case CollectionSortingTypes.ORDER_NUMBER:
                    return this._translateService.instant("PIPES.ORDER_NUMBER");
                case CollectionSortingTypes.DEFAULT_ORDER:
                default:
                    return this._translateService.instant("PIPES.DEFAULT_ORDER");
            }
        } else if (enumType == "subconceptSettings") {
            switch (optionValue) {
                case SubconceptSortingTypes.ALPHABETICAL:
                    return this._translateService.instant("PIPES.ALPHABETICAL");
                case SubconceptSortingTypes.ORDER_NUMBER:
                    return this._translateService.instant("PIPES.ORDER_NUMBER");
                case SubconceptSortingTypes.QUALITY_CODE:
                    return this._translateService.instant("PIPES.QUALITY_CODE");
                case SubconceptSortingTypes.DEFAULT_ORDER:
                default:
                    return this._translateService.instant("PIPES.DEFAULT_ORDER");
            }
        } else if (enumType == "productSettings") {
            switch (optionValue) {
                case ProductSortingTypes.ALPHABETICAL:
                    return this._translateService.instant("PIPES.ALPHABETICAL");
                case ProductSortingTypes.ORDER_NUMBER:
                    return this._translateService.instant("PIPES.ORDER_NUMBER");
                case ProductSortingTypes.DEFAULT_ORDER:
                default:
                    return this._translateService.instant("PIPES.DEFAULT_ORDER");
            }
        }
    }

}