// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular.json`.

export const environment = {
  production: false,
  apiURL: "https://siteapi.penteadora.pt/api/",
  conceptStorage: "https://siteimg.penteadora.pt/concepts/",
  subconceptStorage: "https://siteimg.penteadora.pt/subconcepts/",
  productStorage: "https://siteimg.penteadora.pt/products/",
  collectionStorage: "https://siteimg.penteadora.pt/collections/",
  purchasePdfStorage: "https://siteimg.penteadora.pt/purchases/",
  newsStorage: "https://siteimg.penteadora.pt/news/",
  projectStorage: "https://siteimg.penteadora.pt/projects/",
  socialsStorage: "https://siteimg.penteadora.pt/socials/",
  eventsStorage: "https://siteimg.penteadora.pt/events/",
  slideshowStorage: "https://siteimg.penteadora.pt/slideshow/",
  videoStorage: "https://siteimg.penteadora.pt/video/",
  certificatesStorage: "https://siteimg.penteadora.pt/certificates/",
  logoStorage: "https://siteimg.penteadora.pt/logos/",
  numberStorage: "https://siteimg.penteadora.pt/numbers/",
  historyStorage: "https://siteimg.penteadora.pt/history/",
  sustainabilityStorage: "https://siteimg.penteadora.pt/sustainability/"
};
