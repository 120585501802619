import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InterceptorSkipHeader } from '@helpers/Interceptors/interceptor.helper';
import { map } from 'rxjs/operators';
import { HomeVideo } from '_models/video';
import { environment } from '../environments/environment';


@Injectable({ providedIn: 'root' })
export class VideoService {

    private baseUrl = 'video';

    constructor(private http: HttpClient) { }

    getAllFrontend() {
        const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
        return this.http.get<HomeVideo[]>(`${environment.apiURL}${this.baseUrl}`, { headers }).pipe(map(videos => {
            return videos;
        }));
    }

    getAll() {
        return this.http.get<HomeVideo[]>(`${environment.apiURL}${this.baseUrl}`).pipe(map(videos => {
            return videos;
        }));
    }

    getById(id: string) {
        return this.http.get<HomeVideo>(`${environment.apiURL}${this.baseUrl}/${id}`);
    }

    add(video: HomeVideo) {
        return this.http.post<HomeVideo>(`${environment.apiURL}${this.baseUrl}/`, video);
    }

    update(videoId: string, video: HomeVideo) {
        return this.http.put<HomeVideo>(`${environment.apiURL}${this.baseUrl}/${videoId}`, video);
    }

    delete(id: string) {
        return this.http.delete<boolean>(`${environment.apiURL}${this.baseUrl}/${id}`);
    }

    uploadPosterVideo(file: File): any {
        const formData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post(`${environment.apiURL}${this.baseUrl}/UploadPosterVideo/`, formData);
    }

    uploadVideo(file: File): any {
        const formData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post(`${environment.apiURL}${this.baseUrl}/uploadVideo/`, formData);
    }
}