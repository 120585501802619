<div class="header-logo-section">
  <div fxFlex="70">
    <div class="logo-icon" routerLink="/">
      <img width="305" height="83" alt="logo" [src]="configuration.logo_path" [alt]="configuration.name" />
    </div>
  </div>

  <div fxFlex="30" class="shop-top-toolbar" fxLayoutAlign="end center" *ngIf="!mainPage">
    <button mat-raised-button color="primary" routerLink="/catalogue/cart">
      <mat-icon>shopping_cart</mat-icon>
      <span class="shopping-cart-badge">{{cartData.itemCount}}</span>
    </button>
  </div>
</div>