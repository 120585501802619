import { Configuration } from "./configuration.interface";

export const configuration: Configuration = {
    name: "Penteadora",
    long_name: "Penteadora",
    url: "https://www.penteadora.pt/",
    logo_path: "/assets/images/logos/logo-penteadora.png",
    logo_path_inverse_color: "/assets/images/logos/logo-icon-white.png",
    auth_logo: "/assets/images/logos/logo-penteadora.png",
    phone_number: "+351 275 970 100",
    fax: "+351 275 970 102",
    email: "penteadora@penteadora.pt",
    address: {
        line1: "Lugar da Terça, Avenida 1º Maio",
        line2: "6215-517 Unhais da Serra - Portugal"
    },
    coordinates: "40.251195, -7.620857",
    css: {
        mainColor: "#85724e",
    },
    social_links: {
        facebook: "",
        instagram: "",
        twitter: "",
        linkedin: ""
    },
};
