import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Concept } from '../_models';
import { environment } from '../environments/environment';


const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE, PUT'
    })
};

@Injectable({ providedIn: 'root' })
export class ConceptsService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<Concept[]>(`${environment.apiURL}concepts`).pipe(map(concepts => {
            return concepts;
        }));
    }

    getAllWithNumbers() {
        return this.http.get<Concept[]>(`${environment.apiURL}concepts/admin`).pipe(map(concepts => {
            return concepts;
        }));
    }

    getById(id: string) {
        return this.http.get<Concept>(`${environment.apiURL}concepts/${id}`);
    }

    getAllByCollection(collectionId: string) {
        return this.http.get<Concept[]>(`${environment.apiURL}concepts/${collectionId}/collection`);
    }

    add(concepts: Concept) {
        return this.http.post<Concept>(`${environment.apiURL}concepts/`, concepts);
    }

    update(conceptsId: string, concepts: Concept) {
        return this.http.put<Concept>(`${environment.apiURL}concepts/${conceptsId}`, concepts);
    }

    delete(conceptsId: string) {
        return this.http.delete<boolean>(`${environment.apiURL}concepts/${conceptsId}?cascading=true`);
    }

    updateOrder(id: string, sortSettings) {
        return this.http.put<boolean>(`${environment.apiURL}concepts/${id}/sort`, { option: sortSettings.option, data: sortSettings.sorting });
    }

    uploadConceptImage(file: File): any {
        const formData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post(`${environment.apiURL}concepts/uploadConceptImage/`, formData);
    }

    uploadList(data: any) {
        return this.http.post<Concept>(`${environment.apiURL}concepts/upload`, data);
    }
}