import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { indexToLanguage, languageToIndex } from '@helpers/language';
import { AuthUser } from '@models*';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '@services/authentication.service';
import { configuration } from 'configuration/configuration';

@Component({
  selector: 'app-top-fixed-bar',
  templateUrl: './top-fixed-bar.component.html',
  styleUrls: ['./top-fixed-bar.component.scss']
})
export class TopFixedBarComponent implements OnInit {
  @Input() mainPage: boolean;

  configuration = configuration;

  public availableLangs = [{
    name: 'English',
    shortName: "En",
    code: 'en',
    flag: 'flag-icon-us'
  }, {
    name: 'Português',
    shortName: "Pt",
    code: 'pt',
    flag: 'flag-icon-pt'
  }]
  currentLang
  public currentUser: AuthUser;

  constructor(
    public translate: TranslateService,
    private authenticationService: AuthenticationService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.currentUser = this.authenticationService.currentUserValue;
    let lang;
    if (this.currentUser != null) {
      lang = indexToLanguage(this.currentUser.language);
    } else {
      if (this.translate.currentLang === undefined) {
        const language = localStorage.getItem('user-language');
        if (language == null) {
          lang = 'en';
        } else {
          lang = language;
        }
      } else {
        lang = this.translate.currentLang;
      }
    }

    const findLang = this.availableLangs.find(item => item.code.toUpperCase() == lang.toUpperCase());
    this.currentLang = findLang;
  }

  setLang(lng) {
    this.currentLang = lng;
    this.translate.use(lng.code)
    localStorage.setItem('user-language', lng.code)
    if (this.currentUser) {
      this.authenticationService.updateLanguage(this.authenticationService.currentUserValue, languageToIndex(lng.code));
    }
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/']);
  }
}
