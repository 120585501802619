import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InterceptorSkipHeader } from '@helpers/Interceptors/interceptor.helper';
import { map } from 'rxjs/operators';
import { HomeSlideshow } from '_models/slideshow';
import { environment } from '../environments/environment';


@Injectable({ providedIn: 'root' })
export class SlideshowService {

    private baseUrl = 'slideshow';

    constructor(private http: HttpClient) { }

    getAllFrontend() {
        const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
        return this.http.get<HomeSlideshow[]>(`${environment.apiURL}${this.baseUrl}`, { headers }).pipe(map(slides => {
            return slides;
        }));
    }

    getAll() {
        return this.http.get<HomeSlideshow[]>(`${environment.apiURL}${this.baseUrl}`).pipe(map(slides => {
            return slides;
        }));
    }

    getById(id: string) {
        return this.http.get<HomeSlideshow>(`${environment.apiURL}${this.baseUrl}/${id}`);
    }

    add(slide: HomeSlideshow) {
        return this.http.post<HomeSlideshow>(`${environment.apiURL}${this.baseUrl}/`, slide);
    }

    update(slideshowId: string, slideshow: HomeSlideshow) {
        return this.http.put<HomeSlideshow>(`${environment.apiURL}${this.baseUrl}/${slideshowId}`, slideshow);
    }

    updateSelfOrder(data) {
        return this.http.put<boolean>(`${environment.apiURL}${this.baseUrl}/slidesSort`, data ? [...data] : []);
    }

    delete(id: string) {
        return this.http.delete<boolean>(`${environment.apiURL}${this.baseUrl}/${id}`);
    }

    uploadSlideshowImage(file: File): any {
        const formData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post(`${environment.apiURL}${this.baseUrl}/uploadImage/`, formData);
    }
}