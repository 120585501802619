export enum I18nLanguage {
    NOT_SPECIFIED = 0,
    EN = 1,
    PT = 2
}

export enum AuthenticationStep {
    NO_ERROR = 0,
    TWO_FACTOR_AUTHENTICATION = 1,
    PASSWORD_UPDATE = 2,
    CHOOSE_USER_TYPE = 3
}


export enum RecoverPasswordStep {
    EMAIL = 0,
    CODE = 1,
    PASSWORD_UPDATE = 2,
    CHOOSE_USER_TYPE = 3
}

export enum UserLoginDuration {
    NO_LIMIT = 0,
    HOUR_12 = 4, //12 horas
    HOUR_24 = 1, //24 horas
    HOUR_48 = 2, // 48 horas
    HOUR_168 = 3, //1 semana
    HOUR_336 = 5, //2 semana
    HOUR_730 = 6, //1 mes
    HOUR_1460 = 7, //2 meses
    HOUR_2190 = 8, //3 meses
}

export enum CartItemType {
    CATALOGUE = 0,
    COUPON = 1,
    SAMPLE = 2
}

export enum CollectionSortingTypes {
    DEFAULT_ORDER = 0,
    ORDER_NUMBER = 1
}
export enum ConceptSortingTypes {
    DEFAULT_ORDER = 0,
    ALPHABETICAL = 1,
    ORDER_NUMBER = 2
}

export enum SubconceptSortingTypes {
    DEFAULT_ORDER = 0,
    ALPHABETICAL = 1,
    ORDER_NUMBER = 2,
    QUALITY_CODE = 3,
}

export enum ProductSortingTypes {
    DEFAULT_ORDER = 0,
    ALPHABETICAL = 1,
    ORDER_NUMBER = 2
}

export enum HomeSlideshowSortingTypes {
    DEFAULT_ORDER = 0,
    ORDER_NUMBER = 1
}

export enum HomeCertificateSortingTypes {
    DEFAULT_ORDER = 0,
    ORDER_NUMBER = 1
}