import { Component } from '@angular/core';
import { LogoService } from '@services/logo.service';
import { Logo } from '_models/logo';
import { configuration } from 'configuration/configuration';
import { environment } from 'environments/environment';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  pathLogo = environment.logoStorage;
  projectLogo: Observable<Logo>;

  configuration = configuration;
  currentYear;
  constructor(
    private logoService: LogoService
  ) {
    this.currentYear = new Date().getFullYear();

    this.projectLogo = this.logoService.getByType('projects').pipe(map(data => {
      return data;
    }), catchError(_ => {
      return EMPTY;
    }));
  }
}
