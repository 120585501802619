import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-title-header',
  templateUrl: './title-header.component.html',
  styleUrls: ['./title-header.component.scss']
})
export class TitleHeaderComponent {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() h1: boolean;

  constructor(
    public translate: TranslateService,
  ) { }

}
