import { Pipe, PipeTransform } from "@angular/core";
import { GetReadableDate, GetReadableDateTime } from '_helpers/date.helper';
import { TranslateService } from "@ngx-translate/core";

@Pipe({ name: 'timeFormat' })
export class TimeFormatPipe implements PipeTransform {
    
    constructor(private _translateService: TranslateService) { }

    transform(dataString: string, showTime: boolean = true) {
        if (dataString == null) {
            return this._translateService.instant("PIPES.NO_LOGIN");
        }
        var date = new Date(dataString);
        return showTime ? GetReadableDateTime(date) : GetReadableDate(date)
    }
}