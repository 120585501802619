import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InterceptorSkipHeader } from '@helpers/Interceptors/interceptor.helper';
import { map } from 'rxjs/operators';
import { Logo } from '_models/logo';
import { environment } from '../environments/environment';


@Injectable({ providedIn: 'root' })
export class LogoService {

    private baseUrl = 'logo';

    constructor(private http: HttpClient) { }

    getAllFrontend() {
        const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
        return this.http.get<Logo[]>(`${environment.apiURL}${this.baseUrl}`, { headers }).pipe(map(slides => {
            return slides;
        }));
    }

    getAll() {
        return this.http.get<Logo[]>(`${environment.apiURL}${this.baseUrl}`).pipe(map(slides => {
            return slides;
        }));
    }

    getById(id: string) {
        const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
        return this.http.get<Logo>(`${environment.apiURL}${this.baseUrl}/${id}`, { headers });
    }

    getByType(type: string) {
        const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
        return this.http.get<Logo>(`${environment.apiURL}${this.baseUrl}/type/${type}`, { headers });
    }

    add(slide: Logo) {
        return this.http.post<Logo>(`${environment.apiURL}${this.baseUrl}/`, slide);
    }

    update(logoId: string, logo: Logo) {
        return this.http.put<Logo>(`${environment.apiURL}${this.baseUrl}/${logoId}`, logo);
    }

    delete(id: string) {
        return this.http.delete<boolean>(`${environment.apiURL}${this.baseUrl}/${id}`);
    }

    uploadLogoImage(file: File): any {
        const formData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post(`${environment.apiURL}${this.baseUrl}/uploadImage/`, formData);
    }
}