import { AfterViewInit, Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { indexToLanguage } from '@helpers/language';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { configuration } from 'configuration/configuration';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import { AuthenticationService } from '_services';
import { RoutePartsService } from "./shared/services/route-parts.service";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  appTitle = configuration.name;
  pageTitle = '';

  private popupOpenSubscription: Subscription;
  private currentUser;

  constructor(
    public title: Title,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private routePartsService: RoutePartsService,
    private ccService: NgcCookieConsentService,
    private _translateService: TranslateService,
    private elementRef: ElementRef,
    private authenticationService: AuthenticationService
  ) {
    this.elementRef.nativeElement.style.setProperty('--main-color', configuration.css.mainColor);


    this.currentUser = this.authenticationService.currentUserValue;
    let lang;
    if (this.currentUser != null) {
      lang = indexToLanguage(this.currentUser.language).toLocaleLowerCase();
    } else {
      if (this._translateService.currentLang === undefined) {
        const language = localStorage.getItem("user-language");
        if (language == null) {
          lang = 'en';
        } else {
          lang = language;
        }
      }
    }


    this._translateService.setDefaultLang(lang);
    this._translateService.use(lang);
  }

  ngOnInit() {
    this.changePageTitle();

    this.setCookieContent();

    this._translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.setCookieContent();
    });

  }
  ngAfterViewInit() {
  }
  changePageTitle() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange) => {
      var routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
      if (!routeParts.length)
        return this.title.setTitle(this.appTitle);
      // Extract title from parts;
      this.pageTitle = routeParts
        .reverse()
        .map((part) => part.title)
        .reduce((partA, partI) => { return `${partA} > ${partI}` });
      this.pageTitle += ` | ${this.appTitle}`;
      this.title.setTitle(this.pageTitle);
    });
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

  ngOnDestroy() {
    this.popupOpenSubscription.unsubscribe();
  }

  setCookieContent() {
    this._translateService
      .get(['COOKIE.HEADER', 'COOKIE.MESSAGE', 'COOKIE.DISMISS', 'COOKIE.ALLOW', 'COOKIE.DENY', 'COOKIE.LINK', 'COOKIE.POLICY'])
      .subscribe(data => {

        this.ccService.getConfig().content = this.ccService.getConfig().content || {};
        // Override default messages with the translated ones
        this.ccService.getConfig().content.header = data['COOKIE.HEADER'];
        this.ccService.getConfig().content.message = data['COOKIE.MESSAGE'];
        this.ccService.getConfig().content.dismiss = data['COOKIE.DISMISS'];
        this.ccService.getConfig().content.allow = data['COOKIE.ALLOW'];
        this.ccService.getConfig().content.deny = data['COOKIE.DENY'];
        this.ccService.getConfig().content.link = data['COOKIE.LINK'];
        this.ccService.getConfig().content.policy = data['COOKIE.POLICY'];
        this.ccService.getConfig().content.href = "/privacy";

        this.ccService.destroy();//remove previous cookie bar (with default messages)
        this.ccService.fadeIn();
        this.ccService.init(this.ccService.getConfig()); // update config with translated messages
      });
  }

}
