export class Projects {
    id: string;
    title: string;
    titlePT: string;
    coverImageUrl: string;
    projectImageUrl: string;
    supponsorImageUrl: string;
    supponsorUrl: string;
    schedule: string;
    schedulePT: string;
    leader: string;
    description: string;
    descriptionPT: string;
    startTime: Date;
    endTime: Date;
    investment: string;
    objectives: string[];
    objectivesPT: string[];
    expectedResults: string[];
    expectedResultsPT: string[];
    beneficiary: string;
    beneficiaryInvestment: string;
}