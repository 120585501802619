import { Component, ElementRef, ViewChild, OnDestroy, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'two-factor-authentication',
    templateUrl: 'two-factor-authentication.html',
    styleUrls: ['./two-factor-authentication.scss']
})
export class TwoFactorAuthentication implements OnInit, OnDestroy {
 twofa: FormGroup;
    timerNewCode: number = 120;
    interval;

    @Output() signin: EventEmitter<any> = new EventEmitter();
    @Output() requestNewCodeEmit: EventEmitter<any> = new EventEmitter();


    @ViewChild("pincode1", { static: true }) pincode1: ElementRef;
    @ViewChild("pincode2", { static: true }) pincode2: ElementRef;
    @ViewChild("pincode3", { static: true }) pincode3: ElementRef;
    @ViewChild("pincode4", { static: true }) pincode4: ElementRef;
    @ViewChild("pincode5", { static: true }) pincode5: ElementRef;
    @ViewChild("pincode6", { static: true }) pincode6: ElementRef;

    constructor(
        private cd: ChangeDetectorRef
    ) {

    }

    ngOnInit() {
        this.twofa = new FormGroup({
            pincode1: new FormControl('', [Validators.required, Validators.maxLength(1)]),
            pincode2: new FormControl('', [Validators.required, Validators.maxLength(1)]),
            pincode3: new FormControl('', [Validators.required, Validators.maxLength(1)]),
            pincode4: new FormControl('', [Validators.required, Validators.maxLength(1)]),
            pincode5: new FormControl('', [Validators.required, Validators.maxLength(1)]),
            pincode6: new FormControl('', [Validators.required, Validators.maxLength(1)]),
        })

        this.twofa.get("pincode1").valueChanges.subscribe(val => {
            if (val == "") {
                //Do nothing
            } else {
                this.pincode2.nativeElement.focus();
            }
        })
        this.twofa.get("pincode2").valueChanges.subscribe(val => {
            if (val == "") {
                this.pincode1.nativeElement.focus();
            } else {
                this.pincode3.nativeElement.focus();
            }
        })
        this.twofa.get("pincode3").valueChanges.subscribe(val => {
            if (val == "") {
                this.pincode2.nativeElement.focus();
            } else {
                this.pincode4.nativeElement.focus();
            }
        })
        this.twofa.get("pincode4").valueChanges.subscribe(val => {
            if (val == "") {
                this.pincode3.nativeElement.focus();
            } else {
                this.pincode5.nativeElement.focus();
            }
        })
        this.twofa.get("pincode5").valueChanges.subscribe(val => {
            if (val == "") {
                this.pincode4.nativeElement.focus();
            } else {
                this.pincode6.nativeElement.focus();
            }
        })

        this.twofa.get("pincode6").valueChanges.subscribe(val => {
            if (val == "") {
                this.pincode5.nativeElement.focus();
            } else {
                //do nothing
            }
        })
    }

    twofaSignup() {
        this.startTimer();
        this.signin.emit(null);
    }

    requestNewCode() {
        this.startTimer();
        this.requestNewCodeEmit.emit(null);
    }

    startTimer() {
        this.timerNewCode = 120;
        clearInterval(this.interval);
        
        this.interval = setInterval(() => {
            if (this.timerNewCode > 0) {
                this.timerNewCode--;
            } else {
                clearInterval(this.interval);
            }
        }, 1000)
    }

    ngOnDestroy() {
    }

    ngAfterViewInit() {
        //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
        //Add 'implements AfterViewInit' to the class.
        this.pincode1.nativeElement.focus();

        this.startTimer();
    }

    changeInput(e, currentPosition) {
        if (e.keyCode == 8 || e.key == "Delete") { //Remove 

            this.cd.detectChanges();
            if (currentPosition == 2 && this.pincode2.nativeElement.value == "") {
                this.twofa.get("pincode1").setValue("");
                this.pincode1.nativeElement.focus();
            } else if (currentPosition == 3 && this.pincode3.nativeElement.value == "") {
                this.twofa.get("pincode2").setValue("");
                this.pincode2.nativeElement.focus();
            } else if (currentPosition == 4 && this.pincode4.nativeElement.value == "") {
                this.twofa.get("pincode3").setValue("");
                this.pincode3.nativeElement.focus();
            } else if (currentPosition == 5 && this.pincode5.nativeElement.value == "") {
                this.twofa.get("pincode4").setValue("");
                this.pincode4.nativeElement.focus();
            } else if (currentPosition == 6 && this.pincode6.nativeElement.value == "") {
                this.twofa.get("pincode5").setValue("");
                this.pincode5.nativeElement.focus();
            }
        }
    }

    getCode() {
        if (this.twofa.valid) {
            var content = this.twofa.value;
            return content.pincode1 + content.pincode2 + content.pincode3 + content.pincode4 + content.pincode5 + content.pincode6
        }
        return null;
    }

    clearCode() {
        // this.cd.detectChanges();
        this.twofa.reset();
        this.pincode1.nativeElement.focus();
    }
}
