import { ConceptSortingTypes } from "@helpers/enum";

export class Collection {
  public id: string;
  public name: string;
  public imageUrl: string;
  public visible: boolean;
  public numberConcepts: number;
  public sortSettings: ConceptSortingTypes;
  public orderNumber: number;
}