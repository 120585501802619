import { I18nLanguage } from "_helpers/enum";
import { CartItem } from "./cartItem";
import { UserType } from "@models*";

export class Purchase {
    id: string;
    orderId?: string;
    pdfUrl?: string;
    pUser?: PurchaseUser;
    items: CartItem[];
    observations: string;
    purchaseDate: string;
}

export class ExportPurchase {
    startDate: string;
    endDate: string;
}

export class PurchaseUser {
    id: string;
    userTypes: UserType;

    // COMMOM TO ALL USERS
    email: string;
    name: string;
    phoneNumber: string;
    language: I18nLanguage;

    // FROM USER
    organization?: string;
    clientNumber?: string;
    buyerName?: string;
    marketResp?: string;
    marketEmailResp?: string;
}