import { AdditionalInfo } from "_models/additionalInfo";
import { Concept } from "_models/concept";
import { SubConcept } from "_models/subconcept";

export class Product {
  public id: string;
  public conceptId: string;
  public subConceptId: string;
  public name: string;
  public code: string[];
  public description?: string;
  public reference?: string;
  public features?: string[];
  public imageUrl?: string[];
  public gallery?: string[];
  public badge?: { text: string, color?: string };
  public seen?: boolean;
  public concept?: Concept;
  public subConcept?: SubConcept;
  public colors?: string[];
  public couponColor?: string;
  public additionalInfo?: AdditionalInfo;
  public visible: boolean;
  public orderNumber: number;
}


export class CSVProduct {
  public product_name: any;
  public product_description: any;
  public product_reference: any;
  public product_colors: any;
  public product_code: any;
  public product_couponcolor: any;
  public product_images: any;
  public conceptId: any;
  public subconceptId: any;
}
