<div class="htmlContainer" *ngIf="loaded">
    <div #html2Pdf id="html2Pdf" class="pdf">
        <ng-container *ngFor="let indvCollection of collections; let collNumber = index">
            <div class="page" *ngFor="let articleGroup of indvCollection.samples; let i = index">
                <app-header-pdf [purchase]="purchase" [currentPage]="indvCollection.pages.samples + i"
                    [totalPages]="getTotalNumberPages()"
                    [collectionName]="indvCollection.info ? indvCollection.info.name : 'I21'">
                </app-header-pdf>
                <div class="content">
                    <div class="header">
                        Articles
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Qual.</th>
                                <th [attr.colspan]="maxItemsPerLine">Article Codes</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container
                                *ngFor="let indvArticle of articleGroup | keyvalue: originalOrder; let i = index">
                                <tr [ngClass]="{
                                'even': i % 2== 0,
                                'odd': i % 2 != 0,
                                'several-columns': calculateNumberLinesPerArticle(indvArticle.value).length > 1,
                                'no-borders': pos > 0
                            }" *ngFor="let numberOfLines of calculateNumberLinesPerArticle(indvArticle.value); let pos = index">
                                    <td *ngIf="numberOfLines == 0" style="font-weight: bold">
                                        <span
                                            *ngIf="indvArticle.value && indvArticle.value[0].productCode.split('_').length == 1">

                                        </span>
                                        <span
                                            *ngIf="indvArticle.value && indvArticle.value[0].productCode.split('_').length > 1">
                                            {{indvArticle.value[0].productCode.split('_')[0]}}
                                        </span>
                                    </td>
                                    <td *ngIf="numberOfLines > 0" style="font-weight: bold">
                                        &nbsp;
                                    </td>
                                    <td *ngFor="let number of calculateElements(indvArticle.value, numberOfLines) ">
                                        <span
                                            *ngIf="indvArticle.value[number] && indvArticle.value[number].data.product.name.split('_').length == 1">
                                            {{indvArticle.value[number].data.product.name.split('_')[0]}}
                                        </span>
                                        <span
                                            *ngIf="indvArticle.value[number] && indvArticle.value[number].data.product.name.split('_').length > 1">
                                            {{indvArticle.value[number].data.product.name.split('_')[1]}}
                                        </span>
                                    </td>
                                    <td
                                        *ngFor="let number of calculateMissingElements(indvArticle.value, numberOfLines) ">
                                        <span *ngIf="!indvArticle.value[number]">
                                            &nbsp;
                                        </span>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
                <app-footer-pdf></app-footer-pdf>
            </div>
            <div class="page samples" *ngFor="let indvGroup of indvCollection.catalogues | keyvalue: originalOrder">
                <app-header-pdf [purchase]="purchase" [currentPage]="indvCollection.pages.catalogues"
                    [totalPages]="getTotalNumberPages()"
                    [collectionName]="indvCollection.info ? indvCollection.info.name : 'I21'">
                </app-header-pdf>
                <div class="content">
                    <div class="header">
                        Samples / Coupons
                    </div>
                    <ng-container *ngIf="indvGroup.value.samples.length > 0">
                        <span class="sub_header"> Samples</span>
                        <table>
                            <thead class="special">
                                <tr>
                                    <th>Article</th>
                                    <th>Colour</th>
                                    <th>QtdCol</th>
                                    <th>Size</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let indvSample of indvGroup.value.samples">
                                    <td>{{indvSample.data.product.name.split('_')[0]}}
                                        {{indvSample.data.product.name.split('_')[1]}}</td>
                                    <td>{{indvSample.color}}</td>
                                    <td>{{indvSample.quantity}}</td>
                                    <td>{{indvSample.height}}.{{indvSample.width}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-container>

                    <ng-container *ngIf="indvGroup.value.coupons?.length > 0">
                        <span class="sub_header"> Coupons</span>
                        <table>
                            <thead class="special">
                                <tr>
                                    <th>Article</th>
                                    <th>Colour</th>
                                    <th>Coupons Mts</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let indvCoupon of indvGroup?.value.coupons">
                                    <td>{{indvCoupon.data.product.name.split('_')[0]}}
                                        {{indvCoupon.data.product.name.split('_')[1]}}</td>
                                    <td>{{indvCoupon.color}}</td>
                                    <td>{{indvCoupon.meters | number : '.2-2' }}</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-container>
                </div>
                <app-footer-pdf></app-footer-pdf>
            </div>
        </ng-container>
    </div>
</div>