import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InterceptorSkipHeader } from '_helpers/Interceptors/interceptor.helper';
import { Projects } from '_models/projects';
import { map } from 'rxjs/operators';
import { environment } from '../environments/environment';


@Injectable({ providedIn: 'root' })
export class ProjectsService {
    constructor(private http: HttpClient) { }

    getAllHomepage() {
        const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
        return this.http.get<Projects[]>(`${environment.apiURL}projects`, { headers }).pipe(map(projects => {
            return projects;
        }));
    }

    getAll() {
        return this.http.get<Projects[]>(`${environment.apiURL}projects`).pipe(map(projects => {
            return projects;
        }));
    }

    getById(id: string) {
        const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
        return this.http.get<Projects>(`${environment.apiURL}projects/${id}`, { headers });
    }

    add(projects: Projects) {
        return this.http.post<Projects>(`${environment.apiURL}projects/`, projects);
    }

    update(projectsId: string, projects: Projects) {
        return this.http.put<Projects>(`${environment.apiURL}projects/${projectsId}`, projects);
    }

    uploadProjectsImage(file: File): any {
        const formData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post(`${environment.apiURL}projects/uploadImage/`, formData);
    }

    uploadSupponsorImage(file: File): any {
        const formData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post(`${environment.apiURL}projects/uploadSupponsorImage/`, formData);
    }

    delete(id: string) {
        return this.http.delete<boolean>(`${environment.apiURL}projects/${id}`);
    }
}